@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@font-face {
  font-family: Michelin;
  src: url('./fonts/Michelin-Regular.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Michelin;
  src: url('./fonts/Michelin-SemiBold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Michelin;
  src: url('./fonts/Michelin-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: 'Open Sans', sans-serif;
  background-color: white;
}

h2 {
  font-weight: 700;
  color: $title-color;
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: 20px;
  letter-spacing: -1px;
}

.bg-bfgoodrich {
  background-color: #003366;
}

.bg-bfgoodrich-secondary {
  background-color: #c33;
}

.text-bfgoodrich {
  color: white;
}

.bg-michelin {
  background-color: $primary;
}

.text-michelin {
  color: white;
}

.bg-uniroyal {
  background-color: #ffffff;
}

.text-uniroyal {
  color: $grey-8;
}

.max-app-width {
  max-width: 600px;
  margin: 0 auto;
}
